<template>
  <div>
    <v-select
      :value="value"
      :options="selectList"
      :filterable="false" 
      :searchable="false"
      :clearable="clearable"
      :label="label"
      @open="onOpen"
      @close="onClose"
      :multiple="multiple"
      @input="(val) => $emit('input', val)"
      :readonly="readonly"
      :disabled="readonly"
      :placeholder="placeholder"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          cargando mas opciones...
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: null,
    },
    httpBody: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      observer: null,

      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: "",

      selectList: [],
      selected: "",
    };
  },
  computed: {
    hasNextPage() {
      let output = true;

      if (this.lastPage === this.currentPage) {
        output = false;
      }
      if (this.selectList.length === 0) {
        output = false;
      }

      return output;
    },
  },
  watch: {
    async search() {
      this.currentPage = 1;
      this.selectList = [];
      await this.chargeData();
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async chargeData() {
     const response = await axios.post(`${config.webApiBase + this.resource}/list`, {
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.search,
        ...this.httpBody
      })
    
      this.selectList = this.currentPage > 1 ? 
        [...this.selectList, ...response.data.data] : response.data.data
      
      this.lastPage = response.data.meta.last_page
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.currentPage += 1;
        await this.chargeData();
        ul.scrollTop = scrollTop;
      }
    },
  },
  async mounted() {
    await this.chargeData();
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.form-control:disabled {
  background-color: #f7f5f0 !important;
}
</style>
