<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Equipos
              </h3>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex align-items-center" v-if="currentUser.roles[0].name === 'super_admin'">
                <b-link :to="{ name: 'AddAsset' }">
                  <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                    {{ '+ Añadir equipo' }}
                  </b-button>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <!-- header -->
          <hr class="m-0" />
          <!-- filters -->
          <div class="p-1">
            <b-col cols="12" class="text-right">
              <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </b-col>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="12" md="4">
                    <ProductCategoriesSelect v-model="category" />
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <BaseSelect
                        :resource="'/projects'"
                        @input="project = $event"
                        :value="project"
                        :label="'name'"
                        :placeholder="'Filtrar por obra'"
                        :clearable="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-form-input v-model="searchTerm" :placeholder="'Nombre'" type="text"
                        class="d-inline-block" />
                      <b-button class="ml-1" @click="handleSearch" variant="primary">
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-status">
                      <v-select v-model="status" label="name" :filterable="false" :searchable="false"
                        :options="selectStatus" :placeholder="'Filtrar por Estado'" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <SelectClients v-model="client" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>

          </div>
          <!-- table -->
          <b-table hover responsive :items="items" :fields="columns" class="table-white">
            <template #cell(product.name)="data">
              <b-link :to="{ name: 'viewAsset', params: { id: data.item.id } }" class="link">
                {{ data.item.name }}
              </b-link>
            </template>
            <template #cell(ini)="data">
              <span v-if="data.item.product.type.includes('cortina')">
                {{ data.item.ini }}
              </span>
            </template>
            <template #cell(status)="data">
              <div class="d-flex">
                <b-badge class="d-flex justify-content-center align-items-center position-relative"
                  :variant="statusAssetVariants[data.item.status.alias]" style="top: -3px">
                  <feather-icon :icon="statusAssetIcons[data.item.status.alias]" />&nbsp;{{ data.item.status.name }}
                </b-badge>
                <b-badge v-if="(data.item.alerts)" variant="light-warning" style="margin-left: 5px;">
                  <feather-icon icon="AlertTriangleIcon" size="22" />
                </b-badge>
              </div>
            </template>
            <template #cell(project)="data">
              <b-link v-if="data.item.project" :to="{ name: 'viewProject', params: { id: data.item.id } }" class="link">
                {{ data.item.project.name }}
              </b-link>
            </template>
            <template #cell(actions)="data">

              <span>
                <b-link :to="{ name: 'viewAsset', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editAsset', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BCollapse,
  BFormGroup,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp } from "@/libs/helpers";
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import ProductCategoriesSelect from '@/components/product-categories/select/ProductCategoriesSelect.vue'
import SelectClients from "@/components/clients/select/ClientsSelect.vue";

export default {
  components: {
    SelectClients,
    BCard,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BCollapse,
    vSelect,
    BFormGroup,
    ProductCategoriesSelect,
    BaseSelect
  },
  data() {
    return {
      visibleFilter: false,
      statusAssetIcons: config.statusAssetIcons,
      statusAssetVariants: config.statusAssetVariants,
      project: "",
      client: "",
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Nombre",
          key: "product.name",
        },
        {
          label: "Cod. INI",
          key: "ini",
        },
        {
          label: "Categoría",
          key: "product.type",
        },
        {
          label: "Obra asociada",
          key: "project",
        },
        {
          label: "Cliente",
          key: "client.name",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
      status: '',
      category: ''
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      items: "assets/getItems",
      totalItems: "assets/getTotalItems",
      selectStatus: 'orders/getSelectStatus',
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
    category() {
      this.currentPage = 1;
      this.chargeData();
    },
    orderby() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      list: "assets/getListAssets",
      delete: "projects/delete",
      getSelectStatus: 'orders/getSelectStatus',
    }),
    chargeData() {
      const status_id = this.status ? this.status.id : "";
      const category_id = this.category ? this.category.id : "";

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        status_id,
        category_id,
        id_client: this.client?.id || '',
        project_id: this.project?.id || '',
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteProject(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
  async created() {
    await this.chargeData();
    await this.getSelectStatus({
      type: "asset"
    })

    const alert = { id: 'alert', name: "Alerta", alias: "alert", description: null, type: "asset" }

    this.selectStatus.push(alert)
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
