<template>
  <div>
    <v-select
        :value="value"
        label="name"
        :placeholder="$t('Buscar por categoria')"
        :filterable="true"
        :searchable="true"
        :options="selectCategories"
        @open="onOpen"
        @close="onClose"
        @search="(query) => (search = query)"
        @input="(val) => $emit('input', val)"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          cargando mas opciones...
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import categoriesApi from "@/api/categories-api";

export default {
  name: 'ProductCategoriesSelect',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
  },
  data() {
    return {
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: '',
      selectCategories: [],
      selected: '',
    }
  },
  computed: {
    hasNextPage() {
      let output = true
      if (this.lastPage === this.currentPage) {
        output = false
      }
      if (this.selectCategories.length === 0) {
        output = false
      }

      return output
    },
  },
  watch: {
    async search() {
      this.currentPage = 1
      this.selectCategories = []
      await this.chargeData(true)
    },
  },
  async mounted() {
    await this.chargeData() // Ahora el método chargeData está definido antes de que se llame aquí
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    ...mapActions({
      getSelectCategories: 'categories/getSelectCategories',
    }),
    async chargeData(ifSearch = false) {
      await categoriesApi.getList(this.currentPage, this.pageLength, '', '', true )
        .then(
          response => {
            this.selectCategories = (ifSearch) ? response.data.data : this.selectCategories.concat(response.data.data)
            this.lastPage = response.data.meta.last_page
          },
          () => {
            console.log('ERROR')
          },
        )
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting && this.hasNextPage) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.currentPage += 1
        await this.chargeData()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.form-control:disabled {
  background-color: #f7f5f0 !important;
}
</style>
